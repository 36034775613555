import {Control} from "ol/control";
import {getEventTypes} from "../model/EventTypes";

let addEventIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">\n' +
    '  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>\n' +
    '</svg>';

export class NewEvent extends Control {
    constructor(opt_options){
        const options = opt_options || {}

        const button = document.createElement('button');
        button.innerHTML = addEventIcon;
        button.id = 'add-event';
        button.setAttribute('title', 'Add Event');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'add-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        })

        button.addEventListener('click', this.handleNewEventButtonClick.bind(this), false);
        document.getElementById('create-event-button').addEventListener('click', this.handleCreateEventButtonClick.bind(this), false);
    }

    handleNewEventButtonClick(){
        // get all event types
        // open modal next to add button (or just somewhere) that has a select button of all event types
        // and a create button
        // when the create button is pressed, allow users to draw and save.
        getEventTypes()
            .then((result) => {
                this.renderCreateEventModal(result.types);
            })
    }

    renderCreateEventModal(eventTypes){
        // modal with select of all event types
        // create button
        // show this modal somewhere
        const selectElement = document.getElementById('event-type-select');
        eventTypes.forEach((eventType) => {
            const option = document.createElement('option');
            option.value = eventType.id;
            option.textContent = eventType.name;

            selectElement.appendChild(option);
        })

        this.handleToggleModal()
    }

    handleCreateEventButtonClick(){
        const selectEventTypeElement = document.getElementById('event-type-select');
        const selectedEventTypeId = selectEventTypeElement.value;
        const selectedEventTypeName = selectEventTypeElement.options[selectEventTypeElement.selectedIndex].text;

        document.getElementById('save-event-map-button').setAttribute('data-event-type-id', selectedEventTypeId);
        document.getElementById('save-event').setAttribute('data-event-type-id', selectedEventTypeId);

        document.getElementById('event-type-input').value = selectedEventTypeName;
    }

    handleToggleModal(){
        const addEventModal = new bootstrap.Modal(document.getElementById('create-event-modal'))
        addEventModal.toggle();
    }
}

export const newEventControl = new NewEvent();