import Map from "ol/Map";
import View from "ol/View";
import {clearEventControl} from "./control/ClearEvents";
import {selectTileControl} from "./control/SelectTile";
import {saveEventsControl} from "./control/SaveEvents/SaveEvents"
import {defaults} from "ol/control";
import {fillSizeSliderControl} from "./control/FillSizeSlider";
import {paintLineControl} from "./control/PaintLine";
import {defaults as defaultInteractions} from "ol/interaction";
import {get as getProjection} from "ol/proj";
import {fillRegionControl} from "./control/FillRegion";
import {handleClickEvent} from "./events/ViewEvent";
import {eventDisplayLayer, onZoomChange} from "./layers/EventDisplay";
import {backgroundLayer} from "./layers/Background"
import {drawLayer} from "./layers/Draw";
import {eventLayer} from "./layers/Event";
import {newEventControl} from "./control/NewEvent";
import {filterEventsControl} from "./control/FilterEvents";
import {searchEventsControl} from "./control/SearchEvents";

// controls for the map
const controls = defaults().extend([
    selectTileControl,
    saveEventsControl,
    clearEventControl,
    paintLineControl,
    fillRegionControl,
    newEventControl,
    searchEventsControl,
    filterEventsControl,
    fillSizeSliderControl,
])

// layers we use on the map
const layers = [
    backgroundLayer,
    eventLayer,
    drawLayer,
    eventDisplayLayer
]

// the map view object
const view = new View({
    center: [0, 0],
    zoom: 2,
    extent: getProjection("EPSG:3857").getExtent(),
    constrainResolution: false,
})

const map = new Map({
    target: "map",
    controls: controls,
    interactions: defaultInteractions({shiftDragZoom: false}),
    layers: layers,
    view: view
});

document.getElementById("year-slider").addEventListener("input", function() {
    const year = this.value;
    document.getElementById("year-input").value = year;
    // change source to be baseUrl + year
    // mapLayer.getSource().setUrl(mapLayerTileUrlFunction);
});

document.getElementById("year-input").addEventListener("change", (e)=>{
    document.getElementById("year-slider").value = e.target.value;
    let event = new Event("input");
    document.getElementById("year-slider").dispatchEvent(event);
});

map.addEventListener("click", handleClickEvent)

map.getView().on('change:resolution', () => {
    onZoomChange(map.getView().getZoom());
})

export {map};