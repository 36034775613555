import {Control} from "ol/control";
import {eventDisplayLayer} from "../layers/EventDisplay";

const searchIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">\n' +
    '  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>\n' +
    '</svg>';

export class SearchEvents extends Control{
    searchTerm;
    constructor(opt_options){
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = searchIcon;
        button.id = 'search-event';
        button.setAttribute('title', 'Search Events');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'search-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        })

        this.searchTerm = "";
        button.addEventListener('click', this.handleClickSearchEventButton.bind(this), false);
        document.getElementById('search-event-input').addEventListener('keydown', this.handleSearchInputKeyDown.bind(this), false);
    }

    handleClickSearchEventButton(){
        // open the search menu
        this.handleToggleModal();
    }

    handleInputSearchTerm(){
        let searchInputElement = document.getElementById('search-event-input')
        this.searchTerm = searchInputElement.value;
    }

    handleSearchInputKeyDown(e){
        if(e.key === 'Enter'){
            this.handleInputSearchTerm();
            this.handleCloseModal();
            this.refreshData();
        }
    }

    getSearchTerm(){
        return this.searchTerm;
    }

    refreshData(){
        eventDisplayLayer.getSource().clear();
        eventDisplayLayer.getSource().refresh();
    }

    handleToggleModal(){
        let searchModalElement = document.getElementById('search-event-modal');
        let searchModal = new bootstrap.Modal(searchModalElement)

        this.handleFocusSearchEventInput();

        searchModal.toggle();
    }

    // Focus on the input field after the modal is shown
    handleFocusSearchEventInput(){
        let searchModalElement = document.getElementById('search-event-modal');
        searchModalElement.addEventListener('shown.bs.modal', () => {
            let searchInput = document.getElementById('search-event-input');
            if (searchInput) {
                searchInput.focus();
            }
        }, { once: true });
    }

    handleCloseModal(){
        let searchModalElement = document.getElementById('search-event-modal');
        let searchModal = bootstrap.Modal.getInstance(searchModalElement)
        searchModal.hide();
    }
}

export const searchEventsControl = new SearchEvents();