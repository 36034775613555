import {Control} from "ol/control";
import {Draw, Modify, Snap} from "ol/interaction";
import {map} from "../Map";
import {tileGrid} from "../layers/Common";
import {drawLayer} from "../layers/Draw";
import {LineString} from "ol/geom";
import {plotLine} from "../events";

let paintLineIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brush" viewBox="0 0 16 16">\n' +
    '  <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04M4.705 11.912a1.2 1.2 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.4 3.4 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3 3 0 0 0 .126-.75zm1.44.026c.12-.04.277-.1.458-.183a5.1 5.1 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005zm3.582-3.043.002.001h-.002z"/>\n' +
    '</svg>';

class PaintLineControl extends Control{
    draw;
    snap;
    modify;
    tiles;
    previousTile;
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = paintLineIcon;
        button.id = 'paint-line';
        button.className = 'button-unselected';
        button.setAttribute('title', 'Draw Line');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'paint-line-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        this.tiles = {};

        button.addEventListener('click', this.handlePaintLine.bind(this), false)
        document.addEventListener('trigger_paint_off', this.handlePaintOff.bind(this), false);
        document.addEventListener('clear-events', this.resetDataHandler.bind(this), false);
    }

    handlePaintLine(){
        if(map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        }else if(map.get('fill') === 'true'){
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        }else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        }

        if(map.get('paint') === 'false' || map.get('paint') === undefined){
            this.addInteraction();
            map.set('paint', 'true', true);
            console.log('Paint Selected');
        }
    }

    addInteraction(){
        this.draw = new Draw({
            source: drawLayer.getSource(),
            type: "LineString",
            geometryFunction: (coords, geom) => {
                if (!geom) {
                    geom = new LineString([]);
                }
                geom.setCoordinates(coords);
                geom.setProperties({'tiles': Object.values(this.tiles), 'fill': false})
                return geom.simplify();
            },
            freehand: true
        });

        this.snap = new Snap({
            source: drawLayer.getSource()
        });

        this.modify = new Modify({
            source: drawLayer.getSource()
        })

        map.addInteraction(this.modify);
        map.addInteraction(this.draw);
        map.addInteraction(this.snap);

        this.draw.addEventListener('drawstart', (e) => {
            this.resetPreviousTile();
            map.addEventListener('pointermove', this.drawHandler)
        });
        this.draw.addEventListener('drawend', (e) => {
            console.log('drawing finished')
            map.removeEventListener('pointermove', this.drawHandler)
        });
    }

    drawHandler = (e) => {
        this.handlePointerMove(e)
    }

    resetDataHandler () {
        this.tiles = {};
    }

    handlePointerMove(e) {
        this.previousTile = this.addGeometryXYZCoordinate(e.coordinate, this.previousTile)
    }

    addGeometryXYZCoordinate(coordinate, previousTile){

        let tileCoord =  tileGrid.getTileCoordForCoordAndZ(coordinate, 4);

        let tileCoords = [tileGrid.getTileCoordForCoordAndZ(coordinate, 4)];

        if(tileCoord !== previousTile && previousTile != null){
            // calculate min and max coordinates if not the same
            let xMin, xMax, yMin, yMax

            xMin = previousTile[1]
            xMax = tileCoord[1]
            yMin = previousTile[2]
            yMax = tileCoord[2]

            tileCoords = [...tileCoords, ...plotLine(xMin, yMin, xMax, yMax)]
        }

        for(let tileCoord of tileCoords){
            if(!(`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}` in this.tiles)){
                this.tiles[`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}`] = {z: tileCoord[0]+1,x: tileCoord[1],y: tileCoord[2]}
            }
        }

        return tileCoord;
    }

    removeInteractions(){
        map.removeInteraction(this.draw);
        map.removeInteraction(this.snap);
        map.removeInteraction(this.modify);

        this.draw = null;
        this.snap = null;
        this.modify = null;
    }

    handlePaintOff(e) {
        this.removeInteractions();
        map.set('paint', 'false', true);
        console.log('Paint unselected');
    }

    resetPreviousTile(){
        console.log('resetting previous tile')
        this.previousTile = null;
    }
}

export const paintLineControl = new PaintLineControl();
export {PaintLineControl}