import {Control} from "ol/control";
import {clearEvents, handleEraserModeClick, handleEraserModeDrag} from "../events";
import {map} from "../Map";

let clearIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">\n' +
    '  <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z"/>\n' +
    '</svg>';

class ClearEventsControl extends Control {
    constructor(opt_options) {
        const options = opt_options || {}

        const button = document.createElement('button');
        button.innerHTML = clearIcon;
        button.id = 'clear-event';
        button.setAttribute('title', 'Clear Events');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'clear-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        button.addEventListener('dblclick', this.handleClearEvents.bind(this), false);
        button.addEventListener('click', this.handleEraserMode.bind(this), false);
        document.addEventListener('trigger_eraser_off', this.handleEraserOff.bind(this), false);
    }

    handleClearEvents() {
        clearEvents();
    }

    handleEraserMode(e) {
        if (map.get('fill') === 'true'){
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        }

        if (map.get('erase') === 'false' || map.get('erase') === undefined){
            map.addEventListener('pointerdrag', handleEraserModeDrag);
            map.addEventListener('click', handleEraserModeClick);
            map.set('erase', 'true', true);
            console.log('Eraser selected');
        }
    }

    handleEraserOff(e) {
        map.removeEventListener('pointerdrag', handleEraserModeDrag);
        map.removeEventListener('click', handleEraserModeClick)
        map.set('erase', 'false');
        console.log('Eraser unselected');
    }
}

export const clearEventControl = new ClearEventsControl();

export {ClearEventsControl};