import {eventDisplayLayer} from "../layers/EventDisplay";
import {map} from "../Map";
import {clearEvents} from "../events";
import {Offcanvas} from "bootstrap";
import {deleteEvent} from "../model/Events";
import {editTileHandler} from "./EditEvents";
import {handleEditEventClicked} from "./UpdateEvent";

export const handleClickEvent = (e) => {
    // check if any feature is under event
    let feature = eventDisplayLayer.getSource().getFeaturesAtCoordinate(e.coordinate);
    if(feature.length > 0){
        renderViewEvent(feature[0]);
    }
}

const viewEventAttributes = (eventTypeAttributes) => {
    if (eventTypeAttributes === null){
        return;
    }

    let attributesHTML = [];
    let template = '<div><div class="row"><h5>%NAME%</h5></div><div class="row">%VALUE%</div></div>'

    Object.keys(eventTypeAttributes).forEach((name) => {
        if(eventTypeAttributes[name]['en-US'] !== ""){
            let attributeTemplate = template.replaceAll('%NAME%', name);

            if(eventTypeAttributes[name].type === 'link'){
                let linkTag = `<a href="${eventTypeAttributes[name]['en-US']}" target="_blank">${eventTypeAttributes[name]['en-US']}</a>`
                attributeTemplate = attributeTemplate.replaceAll('%VALUE%', linkTag)
            }else{
                let pTag = `<p>${eventTypeAttributes[name]['en-US']}</p>`
                attributeTemplate = attributeTemplate.replaceAll('%VALUE%', pTag);
            }

            attributesHTML.push(attributeTemplate);
        }
    })

    if (attributesHTML.length !== 0) {
        let attributeDiv = document.getElementById('update-event-attribute-table');
        attributeDiv.className = 'mb-3'
        attributeDiv.innerHTML = attributesHTML.join('');
    }
}

const getEventHTMLTemplate = (name, value, isDescription = false) => {
    let template = `<div><div class="row"><h5>%NAME%</h5></div><div class="row">%VALUE%</div></div>`

    let returnTemplate = template.replaceAll('%NAME%', name);
    if(isDescription){
        returnTemplate = returnTemplate.replaceAll('%VALUE%', value);
    }else{
        let pTag = `<p>${value}</p>`
        returnTemplate = returnTemplate.replaceAll('%VALUE%', pTag);
    }

    return returnTemplate;
}

const renderViewEventData = (event, isModalToggled) => {
    // create the fields here
    let eventHTMLList = [];

    // create attributes
    eventHTMLList.push(getEventHTMLTemplate('Name', event.name))
    eventHTMLList.push(getEventHTMLTemplate('Description', event.description, true))

    let date = new Date(event.date);
    let month = ('0' + (date.getMonth()+1)).slice(-2)
    let dateString = `${month}/${date.getFullYear()}`
    eventHTMLList.push(getEventHTMLTemplate('Date', dateString))

    if(eventHTMLList.length > 0){
        document.getElementById('event-data-div').innerHTML = eventHTMLList.join('');
    }

    viewEventAttributes(event.attributes);

    if(!isModalToggled){
        let viewEventOffCanvasElement = document.getElementById('view-event-offcanvas');
        let viewEventOffCanvas = new Offcanvas(viewEventOffCanvasElement);
        viewEventOffCanvas.toggle();
    }
}

const renderViewEventButtons = (eventId, featureId) => {
    let template = `<button class="btn btn-primary me-2" type="submit" id="edit-event" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Edit</button>` +
    `<button class="btn btn-primary me-2" type="submit" id="edit-tile" data-bs-dismiss="offcanvas" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Edit Tiles</button>` +
    `<button class="btn btn-primary me-2" type="submit" id="delete-event" data-bs-dismiss="offcanvas" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Delete Event</button>`

    template = template.replaceAll('%EVENT_ID%', eventId);
    template = template.replaceAll('%FEATURE_ID%', featureId);

    document.getElementById('event-actions').innerHTML = template;

    document.getElementById('edit-tile').addEventListener('click', editTileHandler);
    document.getElementById('view-event-close').addEventListener('click', closeViewEventHandler)
    document.getElementById('delete-event').addEventListener('click', deleteEventHandler);
    document.getElementById('edit-event').addEventListener('click', handleEditEventClicked);
}

export const renderViewEvent = (feature, isModalToggled = false) => {
    let eventData = feature.getGeometry().getProperties();
    let featureId = feature.getId();

    // create buttons for event viewing
    renderViewEventButtons(eventData.id, featureId);
    renderViewEventData(eventData, isModalToggled);
}

let closeViewEventHandler = () => {
    eventDisplayLayer.setVisible(true);
    map.addEventListener('click', handleClickEvent)

    document.getElementById('event-data-div').innerHTML = "";
    document.getElementById('update-event-attribute-table').innerHTML = "";
    document.getElementById('event-actions').innerHTML = "";

    // remove all attributes related to this event
    document.getElementById('edit-tile').removeAttribute('data-event-id');
    document.getElementById('edit-tile').removeAttribute('data-feature-id');
    document.getElementById('save-event-map-button').removeAttribute('data-event-id');
    document.getElementById('save-event-map-button').removeAttribute('data-feature-id');

    clearEvents();
}

export let deleteEventHandler = (e) => {
    let eventId = document.getElementById('edit-tile').getAttribute('data-event-id');
    let featureId = document.getElementById('edit-tile').getAttribute('data-feature-id');
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);

    deleteEvent(eventId)
        .then((result) => {
            eventDisplayLayer.getSource().removeFeature(feature);
            // resetEventDisplayCache();
            closeViewEventHandler();
            eventDisplayLayer.getSource().refresh();
        })
}