import {Control} from "ol/control";
import {maxZoom, minZoom} from "../layers/Common";

let fillSizeSliderIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bounding-box-circles" viewBox="0 0 16 16">\n' +
    '  <path d="M2 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2M0 2a2 2 0 0 1 3.937-.5h8.126A2 2 0 1 1 14.5 3.937v8.126a2 2 0 1 1-2.437 2.437H3.937A2 2 0 1 1 1.5 12.063V3.937A2 2 0 0 1 0 2m2.5 1.937v8.126c.703.18 1.256.734 1.437 1.437h8.126a2 2 0 0 1 1.437-1.437V3.937A2 2 0 0 1 12.063 2.5H3.937A2 2 0 0 1 2.5 3.937M14 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2M2 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2m12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>\n' +
    '</svg>';

class FillSizeSliderControl extends Control {
    fillSize;
    constructor(opt_options) {
        const options = opt_options || {};

        const slider = document.createElement('input');
        slider.id = 'fill-size-slider';
        slider.setAttribute('type','range')
        slider.setAttribute('min', `${minZoom}`);
        slider.setAttribute('max', `${maxZoom}`);
        slider.setAttribute('value', `${maxZoom}`);
        slider.setAttribute('step', '1');

        const element = document.createElement('div');
        element.className = 'fill-size-slider-div ol-control ol-unselectable';
        element.appendChild(slider);

        super({
            element: element,
            target: options.target
        });

        this.fillSize = maxZoom;

        slider.addEventListener('change', this.handleFillSizeChange.bind(this), false);
    }

    handleFillSizeChange(e) {
        this.setFillSize(e.target.value);
    }

    setFillSize(fillSize){
        this.fillSize = fillSize;
    }

    getFillSize(){
        return this.fillSize;
    }
}

export const fillSizeSliderControl = new FillSizeSliderControl();
export {FillSizeSliderControl};