import {editEvent, editEventAttribute, getEvent} from "../model/Events";
import {eventDisplayLayer} from "../layers/EventDisplay";
import {destroyEditor, drawPolygonFromTiles, editor, setEditor} from "../events";
import {createNewEditor} from "../Editor";
import {renderViewEvent} from "./ViewEvent";

export const handleEditEventClicked = async () => {
    // get event data
    let featureId = document.getElementById('edit-tile').getAttribute('data-feature-id');
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);
    let eventData = feature.getGeometry().getProperties();

    await updateEventModalHandler(eventData);
}

const handleEventAttributeChange = (e) => {
    document.getElementById(e.target.id).value = e.target.value;
}

const addEventAttributeEventListeners = () => {
    let attributes = document.getElementsByClassName('update-attribute-input');
    Array.from(attributes).forEach((attribute) => {
        attribute.addEventListener('input', handleEventAttributeChange);
    })
}

const renderUpdateEventAttributesInput = (eventTypeAttributes) => {
    if (eventTypeAttributes === null) {
        return;
    }

    let attributesHTML = [];
    let template = '<div class="mb-3"><label for="%NAME%" class="form-label">%NAME%</label><input type="%TYPE%" name="%NAME%" id="%NAME%" class="form-control update-attribute-input" value="%VALUE%"></div>';

    Object.keys(eventTypeAttributes).forEach((name) => {
        let attributeTemplate = template.replaceAll('%NAME%', name);

        if(eventTypeAttributes[name].type === 'link'){
            attributeTemplate = attributeTemplate.replaceAll('%TYPE%', 'text');

        }else{
            attributeTemplate = attributeTemplate.replaceAll('%TYPE%', eventTypeAttributes[name].type);
        }
        attributeTemplate = attributeTemplate.replaceAll('%VALUE%', eventTypeAttributes[name]['en-US'])
        attributesHTML.push(attributeTemplate);
    })

    if (attributesHTML.length !== 0) {
        let attributeDiv = document.getElementById('update-event-attribute-table');
        attributeDiv.className = 'mb-3'
        attributeDiv.innerHTML = attributesHTML.join('');

        addEventAttributeEventListeners();
    }
}

let handleSaveEventAttributes = (attributes) => {
    let newAttributes = attributes;
    Object.keys(attributes).forEach((attribute) => {
        let newValue = document.getElementById(attribute).value;
        newAttributes[attribute]['en-US'] = newValue;
    })

    return newAttributes;
}

let handleSaveEventData = async () => {
    let featureId = document.getElementById('save-event-data').getAttribute('data-feature-id');
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);

    let eventData = feature.getGeometry().getProperties();

    let name = document.getElementById('event-name').value;
    let description = editor.getData();
    let date = document.getElementById('event-date').value;

    let newAttributes = handleSaveEventAttributes(eventData.attributes);

    let updatedEventData = {
        id: eventData.id,
        name: name,
        description: description,
        date: date,
        attributes: newAttributes
    };

    return await editEvent(updatedEventData)
        .then( async () => {
            await getEvent(eventData.id)
                .then((dbEvent)=>{
                    let existingFeature = eventDisplayLayer.getSource().getFeatureById(dbEvent.id)
                    drawPolygonFromTiles(dbEvent, existingFeature);
                })
        })
}

const createEditor = async (eventDescription) => {
    if(editor != null){
        destroyEditor();
    }

    setEditor(await createNewEditor('#update-editor')
        .then((newEditor) => {
            newEditor.setData(eventDescription);
            return newEditor;
        })
        .catch((err) => console.log(err)));
}

const renderEventUpdateFields = async (event) => {
    // create the fields
    let template = `<div class="mb-3"><label for="event-name" class="form-label">Name</label><input type="text" class="form-control" id="event-name" value="%NAME_VALUE%"></div>` +
        `<div class="mb-3"><label for="update-editor" class="form-label">Description</label><div id="update-editor"></div></div>` +
        `<div class="mb-3"><label for="event-date" class="form-label">Date</label><input type="date" class="form-control" id="event-date" value="%DATE_VALUE%"></div>`

    let date = new Date(event.date);

    template = template.replaceAll('%NAME_VALUE%', event.name);
    template = template.replaceAll('%DATE_VALUE%', date.toISOString().slice(0, 10));

    document.getElementById('event-data-div').innerHTML = template;

    await createEditor(event.description);
}

const handleResetModal = () => {
    let featureId = document.getElementById('save-event-data').getAttribute('data-event-id');
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);
    renderViewEvent(feature, true);
}

const handleSaveEvent = () => {
    // call save handler
    // after 'saving' go back to view event data with updated data
    handleSaveEventData()
        .then(() => {
            handleResetModal();
        })
}

const handleCancelSaveEvent = () => {
    // go back to view event data
    handleResetModal();
}

const renderUpdateButtons = (eventId) => {
    let template = `<button class="btn btn-primary me-2" type="submit" id="save-event-data" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Save</button>` +
        `<button class="btn btn-primary me-2" type="submit" id="cancel-save-event-data" data-event-id="%EVENT_ID%" data-feature-id="%FEATURE_ID%">Cancel</button>`

    template = template.replaceAll('%EVENT_ID%', eventId);
    template = template.replaceAll('%FEATURE_ID%', eventId);

    document.getElementById('event-actions').innerHTML = template;

    document.getElementById('save-event-data').addEventListener('click', handleSaveEvent);
    document.getElementById('cancel-save-event-data').addEventListener('click', handleCancelSaveEvent);
}

const updateEventModalHandler = async (event)=>{
    await renderEventUpdateFields(event);

    renderUpdateEventAttributesInput(event.attributes);
    renderUpdateButtons(event.id);
}