import {Control} from "ol/control";
import {map} from "../Map";
import {handleTileClickHandler, handleTileDrag} from "../events";

let selectTileIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square" viewBox="0 0 16 16">\n' +
    '  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>\n' +
    '</svg>'

class SelectTileControl extends Control {
    constructor(opt_options) {
        const options = opt_options || {}

        const button = document.createElement('button');
        button.innerHTML = selectTileIcon;
        button.id = 'select-tile';
        button.setAttribute('title', 'Paint Event');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'select-tile-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        button.addEventListener('click', this.handlePaintEvents.bind(this), false);
        document.addEventListener('trigger_fill_off', this.handlePaintEventsOff.bind(this), false);
    }

    handlePaintEvents() {
        if (map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        }

        if (map.get('fill') === 'false' || map.get('fill') === undefined) {
            map.addEventListener('click', handleTileClickHandler);
            map.addEventListener('pointerdrag', handleTileDrag)
            map.set('fill', 'true', true);
            console.log('Highlight selected');
        }
    }

    handlePaintEventsOff(e){
        map.removeEventListener('click', handleTileClickHandler);
        map.removeEventListener('pointerdrag', handleTileDrag);
        map.set('fill', 'false');
        console.log('Highlight unselected');
    }
}

export const selectTileControl = new SelectTileControl();
export {SelectTileControl};