const getEvents = async () => {
    let request = await fetch('/api/events')
    if(!request.ok){
        return [];
    }

    return await request.json();
}

const getEventsByYear = async (year) => {
    let request = await fetch(`/api/events/year/${year}`)
    if(!request.ok){
        return [];
    }

    return await request.json();
}

const getEventsByYearAndZoomAndType = async (year, zoom, types, searchTerm) => {
    let url = `/api/events/${year}/${zoom}`
    let params = new URLSearchParams();

    if(types !== undefined && types.length > 0){
        let typesString = types.join(',')
        params.append('event_type', typesString);
    }

    if(searchTerm !== undefined && searchTerm.length > 0){
        params.append('search', searchTerm);
    }

    url = `${url}?${params.toString()}`;

    let request = await fetch(url)
    if(!request.ok){
        return [];
    }

    return await request.json();
}

const getEvent = async (eventId) => {
    let request = await fetch(`/api/events/${eventId}`)
    if(!request.ok){
        return {};
    }

    return await request.json();
}

const addEvent = async (event) => {
    let request = await fetch(`/api/events`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(event)
    })

    if(!request.ok){
        return 'Failed';
    }

    return await request.json();
}

const editEvent = async (event) => {
    let request = await fetch(`/api/events/${event.id}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(event)
    })

    if(!request.ok){
        return 'Failed';
    }

    return await request.json();
}

const deleteEvent = async (eventId) => {
    let request = await fetch(`/api/events/${eventId}`, {
        method: 'DELETE'
    })
    if(!request.ok){
        return 'Failed';
    }

    return await request.json();
}

const editEventAttribute = async (event) => {
    let request = await fetch(`/api/events/${event.id}/attribute`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(event)
    })

    if(!request.ok){
        return 'Event attribute not saved';
    }

    return await request.json();
}

export {getEvents, getEvent, getEventsByYear, addEvent, editEvent, deleteEvent, getEventsByYearAndZoomAndType, editEventAttribute}