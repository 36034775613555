import {tileGrid} from "./layers/Common";
import {eventLayer} from "./layers/Event"
import {drawLayer} from "./layers/Draw";
import {eventDisplayLayer} from "./layers/EventDisplay";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import {MultiPolygon} from "ol/geom";
import {handleSaveEvent} from "./control/SaveEvents/SaveEvent";
import {fillSizeSliderControl} from "./control/FillSizeSlider";
import {filterEventsControl} from "./control/FilterEvents";
import {searchEventsControl} from "./control/SearchEvents";

// list of all drawn on tiles (at the lowest level ofcourse)
let tiles = {};
export let editor = null;

// clear all events from map
const clearEvents = () => {
    eventLayer.getSource().clear();
    drawLayer.getSource().clear();
    tiles = {};

    document.getElementById('save-event-map-button').removeAttribute('data-event-id');
    document.getElementById('save-event-map-button').removeAttribute('data-event-type-id');

    document.dispatchEvent(new Event('clear-events'));
}

function drawPolygonFromTiles(event, existingFeature = null) {
    let tiles = event.tiles
    let eventPolygon = new MultiPolygon([]);
    for (let tile of tiles) {
        let polygon = getPolygonFromXYZ(tile.z, tile.x, tile.y)
        eventPolygon.appendPolygon(polygon.getGeometry());
    }
    eventPolygon.setProperties(event);
    let feature = new Feature(eventPolygon.transform('EPSG:4326', 'EPSG:3857'));
    feature.setId(event.id);

    if(existingFeature){
        eventDisplayLayer.getSource().removeFeature(existingFeature);
    }

    eventDisplayLayer.getSource().addFeature(feature)
}

document.getElementById('save-event').addEventListener('click', handleSaveEvent);

const zoomRatios = {
    1: 4.523561956,
    2: 6.820178962,
    3: 10.14210706,
    4: 12.1411492,
    5: 14.46301341
}

const getPolygonFromXYZ = (z, x, y, color) => {
    const n = 2 ** zoomRatios[z];
    const left = (x / n) * 360 - 180;
    const right = ((x + 1) / n) * 360 - 180;
    const top = Math.atan(Math.sinh(Math.PI - (2 * Math.PI * y) / n)) * (180 / Math.PI);
    const bottom = Math.atan(Math.sinh(Math.PI - (2 * Math.PI * (y + 1)) / n)) * (180 / Math.PI);

    let feature = new Feature({
        geometry: new Polygon([
            [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom]
            ]
        ])
    });

    feature.getGeometry().setProperties({color})
    return feature;
}

// event highlighting functions
// Recursive function to create polygons for the lowest level of tiles
export function createPolygons(z, x, y, color='#17ff00') {
    // tiles is a hashmap, where the key is `${z}-${x}-${y}`, easy to search for this here, the tile details are in the value
    if(!(`${z}-${x}-${y}` in tiles)){
        tiles[`${z}-${x}-${y}`] = {z,x,y};
    }

    let polygon = getPolygonFromXYZ(z, x, y, color);

    polygon.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    let features = eventLayer.getSource().getFeatures();

    let matchingFeature = features.find((feature) => {
        let extentMatch = JSON.stringify(feature.getGeometry().getExtent()) === JSON.stringify(polygon.getGeometry().getExtent());
        let flatCoordinateMatch = JSON.stringify(feature.getGeometry().getFlatCoordinates()) === JSON.stringify(polygon.getGeometry().getFlatCoordinates());
        return extentMatch && flatCoordinateMatch;
    });

    if(matchingFeature === undefined){
        eventLayer.getSource().addFeature(polygon);
    }
}

function deletePolygons(z, x, y) {
    // Base case: zoom level 5 (or the maximum zoom level)
    if(`${z}-${x}-${y}` in tiles){
        delete tiles[`${z}-${x}-${y}`];
    }

    const polygon = getPolygonFromXYZ(z, x, y);

    polygon.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    let features = eventLayer.getSource().getFeatures();

    let matchingFeature = features.find((feature) => {
        let extentMatch = JSON.stringify(feature.getGeometry().getExtent()) === JSON.stringify(polygon.getGeometry().getExtent());
        let flatCoordinateMatch = JSON.stringify(feature.getGeometry().getFlatCoordinates()) === JSON.stringify(polygon.getGeometry().getFlatCoordinates());
        return extentMatch && flatCoordinateMatch;
    });

    if(matchingFeature !== undefined){
        eventLayer.getSource().removeFeature(matchingFeature);
    }
}

const handleDeleteTile = (z, x, y) => {
    // logic, if tile has new in its property, just delete it
    if(`${z}-${x}-${y}` in tiles && tiles[`${z}-${x}-${y}`].new){
        deletePolygons(z, x, y);
    }else if(`${z}-${x}-${y}` in tiles){
        // if not, try change it color to red and mark it for deletion in the tiles map
        deletePolygons(z, x, y);
        createPolygons(z, x, y, '#ff4f4f')
        tiles[`${z}-${x}-${y}`].delete = true;
    }
}

const handleSelectTile = (z, x, y) => {
    if(!(`${z}-${x}-${y}` in tiles)){
        createPolygons(z, x, y)
        tiles[`${z}-${x}-${y}`].new = true
    }else if(tiles[`${z}-${x}-${y}`].delete){
        deletePolygons(z, x, y);
        createPolygons(z, x, y, '#ffff33')
    }
}

/**
 * get current tile fill size
 * @returns {int}
 */
const getFillSize = () => {
    return fillSizeSliderControl.getFillSize()
}

const handleTileClickHandler = (e) => {
    // Get the clicked coordinate in EPSG:3857 form
    let fillSize = getFillSize();
    let tileCoord = tileGrid.getTileCoordForCoordAndZ(e.coordinate, fillSize-1);
    handleSelectTile(tileCoord[0]+1, tileCoord[1], tileCoord[2]);
}

const handleTileDrag = (e) => {
    if(e.originalEvent.shiftKey) {
        let fillSize = getFillSize();

        let tileCoord = tileGrid.getTileCoordForCoordAndZ(e.coordinate, fillSize-1);
        handleSelectTile(tileCoord[0]+1, tileCoord[1], tileCoord[2]);
    }
}

const handleEraserModeClick = (e) => {
    let fillSize = getFillSize();

    let tileCoord = tileGrid.getTileCoordForCoordAndZ(e.coordinate, fillSize-1);
    handleDeleteTile(tileCoord[0]+1, tileCoord[1], tileCoord[2]);
}

const handleEraserModeDrag = (e) => {
    let fillSize = getFillSize();

    if(e.originalEvent.shiftKey){
        let tileCoord = tileGrid.getTileCoordForCoordAndZ(e.coordinate, fillSize-1);
        handleDeleteTile(tileCoord[0]+1, tileCoord[1], tileCoord[2]);
    }
}

export function getEventTypes(){
    return filterEventsControl.getEventTypes();
}

export function getSearchTerm(){
    return searchEventsControl.getSearchTerm();
}

const plotLineLow = (xMin, yMin, xMax, yMax) => {
    let dx = xMax - xMin;
    let dy = yMax - yMin;
    let yi = 1

    if(dy < 0){
        yi = -1;
        dy = -dy;
    }

    let D = (2 * dy) - dx;
    let y = yMin;

    let coordinates = {};

    for(let i=xMin; i<=xMax; i++){
        let coordinate = [i, y];
        if(!(`${coordinate[0]}${coordinate[1]}` in coordinates)){
            coordinates[`${coordinate[0]}${coordinate[1]}`] = coordinate;
        }

        if(D > 0){
            y = y + yi;
            D = D + (2*(dy - dx));
        }else{
            D = D + 2*dy;
        }
    }

    return Object.values(coordinates).map(b => [4, b[0], b[1]]);
}

const plotLineHigh = (xMin, yMin, xMax, yMax) => {
    let dx = xMax - xMin;
    let dy = yMax - yMin;
    let xi = 1;

    if(dx < 0){
        xi = -1;
        dx = -dx;
    }

    let D = (2 * dx) - dy;
    let x = xMin;

    let coordinates = {};

    for(let i=yMin; i<=yMax; i++){
        let coordinate = [x, i];
        if(!(`${coordinate[0]}${coordinate[1]}` in coordinates)){
            coordinates[`${coordinate[0]}${coordinate[1]}`] = coordinate;
        }

        if(D > 0){
            x = x + xi;
            D = D + (2*(dx - dy));
        }else{
            D = D + 2*dx;
        }
    }

    return Object.values(coordinates).map(b => [4, b[0], b[1]]);
}

// Bresenham's line algorithm implementation
const plotLine = (xMin, yMin, xMax, yMax) => {
    if (Math.abs(yMax - yMin) < Math.abs(xMax - xMin)) {
        if (xMin > xMax) {
            return plotLineLow(xMax, yMax, xMin, yMin);
        } else {
            return plotLineLow(xMin, yMin, xMax, yMax);
        }
    } else {
        if (yMin > yMax) {
            return plotLineHigh(xMax, yMax, xMin, yMin);
        } else {
            return plotLineHigh(xMin, yMin, xMax, yMax);
        }
    }
}

export function setEditor(newEditor){
    editor = newEditor
}

export function destroyEditor(){
    editor.destroy();
}

export function resetTiles(){
    tiles = {}
}

export {tiles}
export {clearEvents, handleTileClickHandler, handleTileDrag, handleEraserModeClick, handleEraserModeDrag, plotLine, getPolygonFromXYZ, drawPolygonFromTiles}