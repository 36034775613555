import {Control} from "ol/control";
import {eventDisplayLayer} from "../layers/EventDisplay";
import {getEventTypes} from "../model/EventTypes";

let filterIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">\n' +
    '  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>\n' +
    '  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>\n' +
    '</svg>';

export class FilterEvents extends Control{
    eventTypes;
    constructor(opt_options){
        const options = opt_options || {}

        const button = document.createElement('button');
        button.innerHTML = filterIcon;
        button.id = 'filter-event';
        button.setAttribute('title', 'Filter Events');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'filter-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        })

        this.eventTypes = [];
        this.initializeEventTypeSelection();

        button.addEventListener('click', this.handleClickFilterEventsButton.bind(this), false);
        document.getElementById('filter-event-button').addEventListener('click', this.handleClickFilterButton.bind(this), false);
    }

    initializeEventTypeSelection(){
        getEventTypes()
            .then((eventTypes)=>{
                eventTypes.types.forEach((eventType) => {
                    this.eventTypes.push(`${eventType.id}`)
                })
            })
    }

    handleClickFilterEventsButton(){
        // when the user clicks on this, we render the popup
        this.renderFilterPopUp()
    }

    handleSelectFilter(e){
        // when something is selected, see if its selected or not, if its selected add to the list
        // if its not remove from the list
        let isChecked = e.target.checked;
        let eventTypeID = e.target.getAttribute('data-event-type-id')
        if(isChecked){
            this.eventTypes.push(eventTypeID);
        }else{
            let index = this.eventTypes.indexOf(eventTypeID);
            this.eventTypes.splice(index, 1);
        }
    }

    handleClickFilterButton(){
        // refresh the map event display layer so we can get the new data
        eventDisplayLayer.getSource().clear();
        eventDisplayLayer.getSource().refresh();
    }

    getEventTypes(){
        return this.eventTypes;
    }

    renderFilterPopUp(){
        // render the popup
        const filterDiv = document.getElementById('filter-event-type-div');
        // render each event type (get it from the database)
        getEventTypes()
            .then((eventTypes) => {
                filterDiv.innerHTML = this.getEventTypeHTML(eventTypes.types);

                this.handleToggleModal();

                this.addEventListeners();

                this.checkSelectedEventTypes();
            })
    }

    checkSelectedEventTypes(){
        const eventTypeElements = document.getElementsByClassName('filter-event-checkbox');

        Array.from(eventTypeElements).forEach((element) => {
            element.checked = this.eventTypes.includes(element.getAttribute('data-event-type-id'))
        })
    }

    getEventTypeHTML(eventTypes){
        let template = '<div class="form-check"><input class="form-check-input filter-event-checkbox" type="checkbox" value="" id="%ID%-event-type-filter" data-event-type-id="%ID%">' +
            '<label class="form-check-label" for="%ID%-event-type-filter">%NAME%</label></div>';

        let returnString = '';

        eventTypes.forEach((eventType) => {
            let eventTypeString = template.replaceAll('%ID%', eventType.id)
            eventTypeString = eventTypeString.replaceAll('%NAME%', eventType.name)
            returnString += eventTypeString
        })

        return returnString
    }

    addEventListeners(){
        // add the event listeners for the filters
        const eventTypeElements = document.getElementsByClassName('filter-event-checkbox');

        Array.from(eventTypeElements).forEach((element) => {
            element.addEventListener('change', this.handleSelectFilter.bind(this))
        })
    }

    handleToggleModal(){
        const addEventModal = new bootstrap.Modal(document.getElementById('filter-event-modal'))
        addEventModal.toggle();
    }
}

export const filterEventsControl = new FilterEvents();