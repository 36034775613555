import {clearEvents, destroyEditor, drawPolygonFromTiles, editor, resetTiles, setEditor, tiles} from "../../events"
import {getEventTypeAttributes} from "../../model/EventTypes";
import {createNewEditor} from "../../Editor";
import {drawLayer} from "../../layers/Draw";
import {addEvent, getEvent} from "../../model/Events";

// create modal to save a new event
export const saveEventModalHandler = async () => {

    if(editor !== null){
        destroyEditor()
    }

    setEditor(await createNewEditor('#create-editor')
        .then(newEditor => {
            newEditor.setData('');
            return newEditor;
        })
        .catch((error) => {
            console.log(error);
        }))

    handleEventTypeAttributes();

    let createEventOffCanvasElement = document.getElementById('create-event-offcanvas');
    let createEventOffCanvas = new bootstrap.Offcanvas(createEventOffCanvasElement);
    createEventOffCanvas.toggle();
}

// get event type default attributes
const handleEventTypeAttributes = () => {
    let eventTypeID = document.getElementById('save-event-map-button').getAttribute('data-event-type-id');
    getEventTypeAttributes(eventTypeID)
        .then((result) => {
            renderCreateEventAttributesInput(result, 'create-event-attribute-table');
        })
}

// delete event functions
let resetCreateEventInputForm = () => {
    document.getElementById('event-name-input').value = '';
    resetTiles();
}


// render default attributes for this event type
const renderCreateEventAttributesInput = (eventTypeAttributes) => {
    let attributesHTML = [];
    let template = '<div class="mb-3"><label for="%NAME%" class="form-label">%NAME%</label><input type="%TYPE%" name="%NAME%" id="%NAME%" class="form-control attribute-input"></div>';

    eventTypeAttributes.forEach((attribute) => {
        let attributeTemplate = template.replaceAll('%NAME%', attribute.name);
        attributeTemplate = attributeTemplate.replaceAll('%TYPE%', attribute.type);
        attributesHTML.push(attributeTemplate);
    })

    if(attributesHTML.length !== 0){
        let attributeDiv = document.getElementById('create-event-attribute-table');
        attributeDiv.className = 'mb-3'
        attributeDiv.innerHTML = attributesHTML.join('');
    }
}

// build attribute object to send to server
function handleEventAttributes(){
    let attributeElements = document.getElementsByClassName('attribute-input')
    let attributeObject = {};
    Array.from(attributeElements).forEach((attributeElement) => {
        let value = attributeElement.value === null ? "" : attributeElement.value;
        attributeObject[attributeElement.id] = {"en-US": value}
    })

    return attributeObject;
}

export const handleSaveEvent = (event) => {
    // get all tiles associated to this event
    // get the description and date and everything related to the event
    let eventName = document.getElementById('event-name-input').value;
    let eventDescription = editor.getData();
    let year = document.getElementById('year-slider').value;
    let date = document.getElementById('event-date-input').value;
    let eventTypeId = document.getElementById('save-event-map-button').getAttribute('data-event-type-id');

    let attributeObject = handleEventAttributes()

    if(date === ''){
        date = `${year}-01-01`;
    }

    let geometryPayload = [];
    let featureArray = drawLayer.getSource().getFeatures();
    for(let i=0; i<featureArray.length; i++){
        geometryPayload.push(featureArray[i].getGeometry().getProperties());
    }

    let newEvent = {
        'type': eventTypeId,
        'name': eventName,
        'description': eventDescription,
        'date': date,
        'tiles': Object.values(tiles),
        'geometries': geometryPayload,
        'attributes': attributeObject
    }

    addEvent(newEvent).then((result)=>{
        let dbEventId = result.event_id;
        getEvent(dbEventId)
            .then((dbEvent)=>{
                drawPolygonFromTiles(dbEvent);
            })
    });

    resetCreateEventInputForm();

    clearEvents();
}