import {editEvent, getEvent} from "../../model/Events";
import {eventDisplayLayer} from "../../layers/EventDisplay";
import {eventLayer} from "../../layers/Event";
import {clearEvents, drawPolygonFromTiles, tiles} from "../../events";
import {handleClickEvent} from "../../events/ViewEvent";
import {map} from "../../Map";


let closeViewEventHandler = () => {
    eventDisplayLayer.setVisible(true);
    map.addEventListener('click', handleClickEvent)

    // remove all attributes related to this event
    document.getElementById('edit-tile').removeAttribute('data-event-id');
    document.getElementById('edit-tile').removeAttribute('data-feature-id');
    document.getElementById('save-event-map-button').removeAttribute('data-event-id');
    document.getElementById('save-event-map-button').removeAttribute('data-feature-id');

    clearEvents();
}

export function handleUpdateEvent(featureId, eventData){
    let tileDelta = {
        'delete': Object.values(tiles).filter(tile=>tile.delete),
        'new': Object.values(tiles).filter(tile=>tile.new)
    }

    let updatedEvent = {
        'id': eventData.id,
        'name': eventData.name,
        'description': eventData.description,
        'date': eventData.date,
        'tiles': tileDelta
    }

    editEvent(updatedEvent).then(eventUpdateResult=> {
        console.log(eventUpdateResult);

        // no refresh, remove the geometry and reload it, get it and add it back as a feature
        getEvent(eventData.id)
            .then((eventFromDB) => {
                let feature = eventDisplayLayer.getSource().getFeatureById(featureId);
                eventDisplayLayer.getSource().removeFeature(feature);
                drawPolygonFromTiles(eventFromDB)
            })

        eventLayer.getSource().clear();
    });

    closeViewEventHandler();
}