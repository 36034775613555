import {Control} from "ol/control";
import {handleUpdateEvent} from "./UpdateEvent";
import {saveEventModalHandler} from "./SaveEvent.js"
import {map} from "../../Map";

import {eventDisplayLayer} from "../../layers/EventDisplay"

let saveEventIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy2" viewBox="0 0 16 16">\n' +
    '  <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>\n' +
    '</svg>';

class SaveEventsControl extends Control {
    constructor(opt_options) {
        const options = opt_options || {}

        const button = document.createElement('button');
        button.innerHTML = saveEventIcon;
        button.id = 'save-event-map-button';
        button.setAttribute('title', 'Save Event');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'save-event-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        button.addEventListener('click', this.handleSaveEvents.bind(this), false);
    }

    async handleSaveEvents(e) {
        // check if we need to save a new event or update and old event
        // turn off all modes
        this.removeInteractions()

        let eventId = document.getElementById('save-event-map-button').getAttribute('data-event-id')
        if(eventId){
            let featureId = document.getElementById('save-event-map-button').getAttribute('data-feature-id');
            let feature = eventDisplayLayer.getSource().getFeatureById(featureId)
            let eventData = feature.getProperties().geometry.values_;

            handleUpdateEvent(featureId, eventData);
        }else{
            await saveEventModalHandler();
        }
    }

    removeInteractions(){
        if (map.get('fill') === 'true'){
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill-region') === 'true'){
            let trigger_fill_region_off_event = new Event('trigger_fill_region_off');
            document.dispatchEvent(trigger_fill_region_off_event);
        } else if (map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        }
    }
}

export const saveEventsControl = new SaveEventsControl();
export {SaveEventsControl};