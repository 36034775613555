import {Control} from "ol/control";
import {map} from "../Map";
import {drawLayer} from "../layers/Draw";
import {handleTileClickHandler, handleTileDrag} from "../events";
import {Select} from "ol/interaction";
import {Fill, Stroke, Style} from "ol/style";

let fillEventIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paint-bucket" viewBox="0 0 16 16">\n' +
    '  <path d="M6.192 2.78c-.458-.677-.927-1.248-1.35-1.643a3 3 0 0 0-.71-.515c-.217-.104-.56-.205-.882-.02-.367.213-.427.63-.43.896-.003.304.064.664.173 1.044.196.687.556 1.528 1.035 2.402L.752 8.22c-.277.277-.269.656-.218.918.055.283.187.593.36.903.348.627.92 1.361 1.626 2.068.707.707 1.441 1.278 2.068 1.626.31.173.62.305.903.36.262.05.64.059.918-.218l5.615-5.615c.118.257.092.512.05.939-.03.292-.068.665-.073 1.176v.123h.003a1 1 0 0 0 1.993 0H14v-.057a1 1 0 0 0-.004-.117c-.055-1.25-.7-2.738-1.86-3.494a4 4 0 0 0-.211-.434c-.349-.626-.92-1.36-1.627-2.067S8.857 3.052 8.23 2.704c-.31-.172-.62-.304-.903-.36-.262-.05-.64-.058-.918.219zM4.16 1.867c.381.356.844.922 1.311 1.632l-.704.705c-.382-.727-.66-1.402-.813-1.938a3.3 3.3 0 0 1-.131-.673q.137.09.337.274m.394 3.965c.54.852 1.107 1.567 1.607 2.033a.5.5 0 1 0 .682-.732c-.453-.422-1.017-1.136-1.564-2.027l1.088-1.088q.081.181.183.365c.349.627.92 1.361 1.627 2.068.706.707 1.44 1.278 2.068 1.626q.183.103.365.183l-4.861 4.862-.068-.01c-.137-.027-.342-.104-.608-.252-.524-.292-1.186-.8-1.846-1.46s-1.168-1.32-1.46-1.846c-.147-.265-.225-.47-.251-.607l-.01-.068zm2.87-1.935a2.4 2.4 0 0 1-.241-.561c.135.033.324.11.562.241.524.292 1.186.8 1.846 1.46.45.45.83.901 1.118 1.31a3.5 3.5 0 0 0-1.066.091 11 11 0 0 1-.76-.694c-.66-.66-1.167-1.322-1.458-1.847z"/>\n' +
    '</svg>'

const selectStyle = new Style({
    fill: new Fill({
        color: '#eeeeee',
    }),
    stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
        width: 5,
    }),
});

class FillRegionControl extends Control {
    select;
    constructor(opt_options) {
        const options = opt_options || {}

        const button = document.createElement('button');
        button.innerHTML = fillEventIcon;
        button.id = 'fill-region';
        button.setAttribute('title', 'Fill Region');
        button.setAttribute('type', 'button');

        const element = document.createElement('div');
        element.className = 'fill-region-div ol-control ol-unselectable map-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        button.addEventListener('click', this.handleFillRegionEvents.bind(this), false);
        document.addEventListener('trigger_fill_region_off', this.handleFillRegionOffEvent.bind(this), false);
    }

    handleFillRegionEvents() {
        if (map.get('erase') === 'true'){
            let trigger_eraser_off_event = new Event('trigger_eraser_off');
            document.dispatchEvent(trigger_eraser_off_event);
        } else if (map.get('paint') === 'true'){
            let trigger_paint_off_event = new Event('trigger_paint_off');
            document.dispatchEvent(trigger_paint_off_event);
        } else if(map.get('fill') === 'true') {
            let trigger_fill_off_event = new Event('trigger_fill_off');
            document.dispatchEvent(trigger_fill_off_event);
        }

        if (map.get('fill-region') === 'false' || map.get('fill-region') === undefined) {
            this.addInteraction();
            map.set('fill-region', 'true');
        }
    }

    handleFillRegionOffEvent(e){
        map.set('fill-region', 'false');
        console.log('Fill Region unselected');
        this.removeInteraction();
    }

    addInteraction(){
        console.log('Fill Region selected')
        this.select = new Select({
            style: selectStyle
        })

        map.addInteraction(this.select);

        this.select.on('select', this.handleSelectFeature);
    }

    removeInteraction(){
        map.removeInteraction(this.select);
        this.select = null;
    }

    handleSelectFeature(e){
        let feature = e.target.getFeatures().getArray()[0];

        let editedFeature = drawLayer.getSource().getFeatureByUid(feature.ol_uid);
        let editedFeatureProperties = editedFeature.getProperties();
        editedFeature.getGeometry().setProperties({'tiles': editedFeatureProperties['geometry']['values_']['tiles'], 'fill': !editedFeatureProperties['geometry']['values_']['fill']}, false)

        drawLayer.changed();
    }
}

export const fillRegionControl = new FillRegionControl();
export {FillRegionControl};